import { Link } from '@remix-run/react'
import { Check } from 'lucide-react'
import { useEffect } from 'react'
import { setupScrollAnimations } from '#app/utils/animations'

interface PricingPlanProps {
	title: string
	price: string
	period: string
	description: string
	features: string[]
	popular?: boolean
	user?: string
}

const PricingPlan = ({
	title,
	price,
	period,
	description,
	features,
	popular = false,
	user,
}: PricingPlanProps) => {
	return (
		<div
			className={`animate-on-scroll relative overflow-hidden rounded-2xl ${popular ? 'border-2 border-primary shadow-xl' : 'border border-gray-200 shadow-lg'}`}
		>
			{popular && (
				<div className="absolute right-0 top-0 bg-primary px-4 py-1 text-sm font-medium text-white">
					Popular
				</div>
			)}
			<div className="bg-white p-6 dark:bg-gray-900">
				<h3 className="mb-2 text-xl font-bold">{title}</h3>
				<p className="mb-4 text-foreground/70">{description}</p>
				<div className="mb-6">
					<span className="text-3xl font-bold">{price}</span>
					<span className="ml-1 text-foreground/60">{period}</span>
				</div>
				<Link
					prefetch="intent"
					to={'/anuncios'}
					className={`mb-6 block rounded-lg px-6 py-3 text-center font-medium transition-colors ${
						popular
							? 'bg-primary text-white hover:bg-primary/90'
							: 'bg-gray-100 text-foreground hover:bg-gray-200'
					}`}
				>
					{title === 'Básico' ? 'Criar Anúncio' : 'Escolher Plano'}
				</Link>
				<ul className="space-y-3">
					{features.map((feature, index) => (
						<li key={index} className="flex items-start">
							<div className="mr-3 mt-0.5 flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-primary/10 text-primary">
								<Check className="h-3 w-3" />
							</div>
							<span className="text-sm text-foreground/80">{feature}</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}

const PricingTable = ({ user }: { user?: string }) => {
	useEffect(() => {
		setupScrollAnimations()
	}, [])

	const plans = [
		{
			title: 'Básico',
			price: 'R$99',
			period: '/anúncio',
			description: 'Ideal para vendedores ocasionais de veículos elétricos.',
			features: [
				'1 ANO de visibilidade',
				'Fotos em alta resolução',
				'Destaque na página inicial',
				'Métricas de visualização e engajamento',
				'Suporte por e-mail',
			],
		},
		// {
		//   title: "Profissional",
		//   price: "R$199",
		//   period: "/mês",
		//   description: "Perfeito para revendedores de veículos elétricos.",
		//   features: [
		//     "Até 10 anúncios ativos",
		//     "60 dias de visibilidade",
		//     "Fotos em alta resolução (até 20)",
		//     "Tour virtual 360°",
		//     "Destaque na página inicial",
		//     "Métricas avançadas",
		//     "Suporte prioritário"
		//   ],
		//   popular: true
		// },
		{
			title: 'Empresarial',
			price: 'R$399',
			period: '/mês',
			description: 'Para concessionárias e grandes revendedores.',
			features: [
				'Anúncios ilimitados',
				'1 ANO de visibilidade',
				'Fotos em alta resolução',
				'Destaque na página inicial',
				'Métricas de visualização e engajamento',
				'Perfil da concessionária',
				'Integração com CRM',
				'API para sincronização de estoque',
				'Gerente de conta dedicado',
				'Link em destaque para o seu site',
			],
			popular: true,
		},
	]

	return (
		<section id="pricing" className="section bg-white">
			<div className="mx-auto max-w-7xl">
				{/* Background decorations */}
				<div className="absolute inset-0 overflow-hidden">
					<div className="absolute left-10 top-20 h-72 w-72 rounded-full bg-blue-100 opacity-30 blur-3xl"></div>
					<div className="absolute bottom-20 right-10 h-72 w-72 rounded-full bg-primary/10 opacity-30 blur-3xl"></div>
				</div>

				<div className="relative z-10 mb-16 text-center">
					<div className="mb-4 inline-flex items-center rounded-full bg-primary/10 px-3 py-1 text-sm font-medium text-primary">
						<span>Planos de Anúncio</span>
					</div>
					<h2 className="mb-4 text-3xl font-bold md:text-4xl">
						Escolha o plano ideal para você
					</h2>
					<p className="mx-auto max-w-2xl text-foreground/70">
						Oferecemos opções flexíveis que se adaptam às suas necessidades,
						seja você um vendedor particular ou uma concessionária.
					</p>
				</div>

				<div className="grid grid-cols-1 gap-8 md:mx-auto md:max-w-5xl md:grid-cols-2">
					{plans.map((plan, index) => (
						<PricingPlan
							key={index}
							title={plan.title}
							price={plan.price}
							period={plan.period}
							description={plan.description}
							features={plan.features}
							popular={plan.popular}
							user={user}
						/>
					))}
				</div>

				<div className="animate-on-scroll mt-16 text-center">
					<p className="mb-4 text-foreground/70">
						Precisa de um plano personalizado para sua empresa?
					</p>
					<Link
						prefetch="intent"
						to="mailto:contato@carregados.com.br"
						className="inline-flex items-center justify-center rounded-full border border-primary px-6 py-3 font-medium text-primary transition-all hover:bg-primary/10"
					>
						Fale Conosco para Cotação Personalizada
					</Link>
				</div>
			</div>
		</section>
	)
}

export default PricingTable
