import { Link } from '@remix-run/react';
import { useEffect } from 'react';
import { setupScrollAnimations } from '#app/utils/animations';

interface ArticleCardProps {
  to: string;
  image: string;
  title: string;
  excerpt: string;
  date: string;
  author: string;
  category: string;
}

function ArticleCard({ 
  to, 
  image, 
  title, 
  excerpt, 
  date, 
  author, 
  category 
}: ArticleCardProps) {
  return (
    <Link
      to={to}
      className="animate-on-scroll bg-white dark:bg-gray-900 rounded-xl overflow-hidden shadow-lg transition-all duration-300 hover:shadow-xl hover:translate-y-[-5px] group"
    >
      <div className="relative aspect-video overflow-hidden">
        <img 
          src={image} 
          alt={title} 
          loading="lazy"
          className="w-full h-full object-cover"
        />
        <div className="absolute top-3 left-3 bg-primary/90 text-white text-xs font-medium px-2 py-1 rounded-full">
          {category}
        </div>
      </div>
      <div className="p-6">
        <h3 className="text-xl font-bold mb-3 group-hover:text-primary transition-colors">{title}</h3>
        <p className="text-foreground/70 dark:text-gray-300 mb-4">{excerpt}</p>
        <div className="flex items-center text-sm text-foreground/60 dark:text-gray-400 mb-4">
          <div className="flex items-center mr-4">
            <svg className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
            </svg>
            <span>{date}</span>
          </div>
          <div className="flex items-center">
            <svg className="h-4 w-4 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            <span>{author}</span>
          </div>
        </div>
        <div className="flex items-center">
          <span className="font-medium text-primary transition-colors duration-300 group-hover:underline dark:text-primary-foreground">
            Ler artigo completo
          </span>
          <svg
            className="ml-1 h-4 w-4 text-primary transition-transform duration-300 group-hover:translate-x-1 dark:text-primary-foreground"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
}

export function Articles({ user }: { user?: string }) {
  useEffect(() => {
    setupScrollAnimations();
  }, []);

  const articles = [
    {
      to: 'quanto-tempo-demora-para-carregar-um-carro-eletrico',
      image: '/img/quanto-tempo-demora.webp',
      title: 'Quanto tempo demora para carregar um carro elétrico?',
      excerpt: 'Dicas práticas para lidar com a recarga do seu veículo elétrico e garantir viagens tranquilas em qualquer situação.',
      date: '22 Mar 2025',
      author: 'Denis Ricardo',
      category: 'Guias'
    },
    {
      to: 'quanto-custa-para-carregar-um-carro-eletrico',
      image: '/img/quanto-custa-carregar.webp',
      title: 'Quanto custa para carregar um carro elétrico?',
      excerpt: 'Conheça todos os custos associados ao carregamento de veículos elétricos em casa e em pontos públicos.',
      date: '18 Mar 2025',
      author: 'Denis Ricardo',
      category: 'Análises'
    },
    {
      to: 'glossario',
      image: '/img/glossario.webp',
      title: 'Glossário Elétrico',
      excerpt: 'Um guia completo de todos os termos e conceitos que você precisa conhecer sobre carros elétricos.',
      date: '04 Jan 2025',
      author: 'Denis Ricardo',
      category: 'Recursos'
    }
  ];

  return (
    <section id="blog" className="section relative bg-gray-50 dark:bg-gray-900/50">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-20 left-10 w-72 h-72 bg-blue-100 rounded-full opacity-30 blur-3xl"></div>
        <div className="absolute bottom-20 right-10 w-72 h-72 bg-primary/10 rounded-full opacity-30 blur-3xl"></div>
      </div>
      
      <div className="relative z-10 mb-16 text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
          <span>Artigos</span>
        </div>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Novidades do mundo elétrico</h2>
        <p className="text-foreground/70 dark:text-gray-300 max-w-2xl mx-auto">
          Artigos, tutoriais e análises sobre veículos elétricos, tecnologia de carregamento e mobilidade sustentável.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
        {articles.map((article, index) => (
          <ArticleCard
            key={index}
            to={article.to}
            image={article.image}
            title={article.title}
            excerpt={article.excerpt}
            date={article.date}
            author={article.author}
            category={article.category}
          />
        ))}
      </div>

      <div className="animate-on-scroll mt-16 bg-primary/5 dark:bg-primary/10 rounded-xl p-8 flex flex-col md:flex-row items-center justify-between gap-8 max-w-6xl mx-auto">
        <div className="md:max-w-md">
          <h3 className="text-2xl font-bold text-primary dark:text-white mb-2">Fique atualizado</h3>
          <p className="text-foreground/70 dark:text-gray-300">
            Crie sua conta para acompanhar as últimas novidades, artigos e atualizações sobre o mundo dos veículos elétricos.
          </p>
        </div>
        <div className="w-full md:w-auto">
          <div className="flex flex-col sm:flex-row gap-3">
            <Link 
              prefetch="intent"
              to={user ? `/carros` : "/login"} 
              className="bg-primary text-white w-full font-medium px-6 py-3 rounded-lg hover:bg-primary/90 transition-colors text-center"
            >
              Criar conta
            </Link>
          </div>
          <p className="text-xs text-foreground/60 dark:text-gray-400 mt-2">
            Acesso a conteúdo exclusivo e ferramentas para proprietários de veículos elétricos.
          </p>
        </div>
      </div>
      {/* <div className="animate-on-scroll mt-16 text-center">
        <Link 
          to="/blog" 
          className="inline-flex items-center px-5 py-2 rounded-full border border-primary text-primary font-medium transition-all hover:bg-primary/10"
        >
          Ver todos os artigos
        </Link>
      </div> */}
    </section>
  );
}
