import { Link } from '@remix-run/react';
import { Route, Zap, Clock, Car, ArrowRight } from 'lucide-react';
import { useEffect } from 'react';
import { setupScrollAnimations } from '#app/utils/animations';

const RoutePlanner = () => {
  useEffect(() => {
    setupScrollAnimations();
  }, []);

  return (
    <section id="route-planner" className="section relative bg-white">
      <div className="max-w-7xl mx-auto">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-20 left-10 w-72 h-72 bg-blue-100 rounded-full opacity-30 blur-3xl"></div>
        <div className="absolute bottom-20 right-10 w-72 h-72 bg-primary/10 rounded-full opacity-30 blur-3xl"></div>
      </div>
      
      <div className="relative z-10 mb-12 text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
          <span>Planejador de Rotas</span>
        </div>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Viaje sem preocupações</h2>
        <p className="text-foreground/70 max-w-2xl mx-auto">
          Planeje suas viagens de carro elétrico com nosso sistema inteligente que mapeia os melhores pontos de recarga no seu caminho.
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
        <div className="animate-on-scroll order-2 lg:order-1">
          <div className="bg-white dark:bg-gray-900 rounded-xl p-6 shadow-lg">
            <h3 className="text-xl font-bold mb-4 flex items-center">
              <Route className="mr-2 text-primary" size={22} />
              Planeje sua rota
            </h3>

            <div className="space-y-4 mb-6">
              <p className="text-foreground/70">
                Nosso planejador de rotas inteligente ajuda você a viajar com seu veículo elétrico sem preocupações, 
                identificando os melhores pontos de recarga no caminho entre sua origem e destino.
              </p>
              
              <Link 
                to="/mapa?planejar=viagem"
                className="w-full bg-primary text-white font-medium py-3 rounded-lg hover:bg-primary/90 transition-colors flex items-center justify-center"
              >
                Planejar Minha Viagem
                <ArrowRight className="ml-2" size={18} />
              </Link>
            </div>

            <div className="border-t border-gray-100 pt-4">
              <h4 className="font-medium mb-3">Rotas populares:</h4>
              <div className="space-y-3">
                <Link 
                  to="/mapa?planejar=viagem" 
                  className="block w-full text-left py-2 px-3 bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors text-sm"
                >
                  São Paulo → Rio de Janeiro (430 km)
                </Link>
                <Link 
                  to="/mapa?planejar=viagem" 
                  className="block w-full text-left py-2 px-3 bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors text-sm"
                >
                  Curitiba → Florianópolis (300 km)
                </Link>
                <Link 
                  to="/mapa?planejar=viagem" 
                  className="block w-full text-left py-2 px-3 bg-gray-50 hover:bg-gray-100 rounded-lg transition-colors text-sm"
                >
                  Belo Horizonte → Brasília (716 km)
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="animate-on-scroll md:pb-14 order-1 lg:order-2 space-y-8">
          <div className="bg-white dark:bg-gray-900 rounded-xl p-6 shadow-lg">
            <h3 className="text-xl font-bold mb-6">Por que usar nosso planejador?</h3>
            
            <div className="space-y-6">
              <div className="flex">
                <div className="flex-shrink-0 w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center text-primary mr-4">
                  <Zap size={24} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Elimine a ansiedade de autonomia</h4>
                  <p className="text-foreground/70">Mapeamos cada trecho para garantir que você sempre tenha uma estação de recarga acessível.</p>
                </div>
              </div>
              
              <div className="flex">
                <div className="flex-shrink-0 w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center text-primary mr-4">
                  <Clock size={24} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Otimize seu tempo</h4>
                  <p className="text-foreground/70">Nossas rotas consideram o tempo de carregamento e sugerem paradas estratégicas.</p>
                </div>
              </div>
              
              <div className="flex">
                <div className="flex-shrink-0 w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center text-primary mr-4">
                  <Car size={24} />
                </div>
                <div>
                  <h4 className="font-bold text-lg">Específico para seu veículo</h4>
                  <p className="text-foreground/70">Cada EV é único. Consideramos o modelo, capacidade da bateria e tipo de carregador compatível.</p>
                </div>
              </div>
            </div>
          </div>
          
          {/* <div className="bg-primary/5 rounded-xl p-6">
            <h4 className="font-bold mb-2">Informações em tempo real</h4>
            <p className="text-foreground/70 mb-4">
              Nosso aplicativo móvel oferece atualizações em tempo real durante sua viagem, incluindo disponibilidade de estações e notificações de desvio.
            </p>
            <a 
              href="#" 
              className="inline-flex items-center text-primary font-medium hover:underline"
            >
              Baixe o aplicativo
              <ArrowRight className="ml-1 h-4 w-4" />
            </a>
          </div> */}
        </div>
      </div>
      </div>
    </section>
  );
};

export default RoutePlanner;