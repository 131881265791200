import { type SEOHandle } from '@nasa-gcn/remix-seo'
import {
	type ActionFunctionArgs,
	type HeadersFunction,
	json,
	type LinksFunction,
	type LoaderFunctionArgs,
	type MetaFunction,
	redirect,
} from '@remix-run/node'
import { Link, useFetcher, useLoaderData } from '@remix-run/react'
import React, { useEffect } from 'react'
import { serverOnly$ } from 'vite-env-only/macros'

import Benefits from '#app/components/benefits.tsx'
import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import { FeaturedListings } from '#app/components/featured-listings.tsx'
import Hero from '#app/components/hero.tsx'
import { MapMain } from '#app/components/map-main.tsx'
import PricingTable from '#app/components/pricing-table.tsx'
import RoutePlanner from '#app/components/route-planner.tsx'
import Testimonials from '#app/components/testimonials.tsx'
import { Articles } from '#app/components/tools.tsx'
import FAQ from '#app/routes/_marketing+/FAQ.tsx'
import { prisma } from '#app/utils/db.server.ts'
import {
	invariantResponse,
	reuseUsefulLoaderHeaders,
} from '#app/utils/misc.tsx'
import {
	// combineServerTimings,
	makeTimings,
	time,
} from '#app/utils/timing.server.ts'
import { useOptionalUser } from '#app/utils/user.ts'
export const headers: HeadersFunction = reuseUsefulLoaderHeaders

export const links: LinksFunction = () => {
	return [
		{
			rel: 'preload',
			href: 'https://carregados.com.br/cdn-cgi/image/height=420,width=480,format=auto,fit=crop/img/bannerm2.webp',
			as: 'image',
		},
		{
			rel: 'preload',
			href: 'https://carregados.com.br/cdn-cgi/image/height=420,width=640,format=auto,fit=crop/img/bannerm2.webp',
			as: 'image',
		},
	]
}

export const meta: MetaFunction = () => [
	{ title: 'Carregados - Marketplace de Carros Elétricos' },
	{
		name: 'description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos no Brasil.',
	},
	{ itemProp: 'name', content: 'Carregados - Marketplace de Carros Elétricos' },
	{
		itemProp: 'description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos no Brasil.',
	},
	{ itemProp: 'image', content: 'https://carregados.com.br/img/og-main.jpg' },
	{ property: 'og:url', content: 'https://carregados.com.br' },
	{ property: 'og:type', content: 'website' },
	{
		property: 'og:title',
		content: 'Carregados - Marketplace de Carros Elétricos',
	},
	{
		property: 'og:description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos no Brasil.',
	},
	{
		property: 'og:image',
		content: 'https://carregados.com.br/img/og-main.jpg',
	},
	{ name: 'twitter:card', content: 'summary_large_image' },
	{
		name: 'twitter:title',
		content: 'Carregados - Marketplace de Carros Elétricos',
	},
	{
		name: 'twitter:description',
		content:
			'Encontre o carro elétrico perfeito para você. Venda seu carro elétrico rapidamente e sem complicações. Carregados é o maior marketplace exclusivo para carros elétricos do Brasil.',
	},
	{
		name: 'twitter:image',
		content: 'https://carregados.com.br/img/og-main.jpg',
	},
	{
		name: 'twitter:image:alt',
		content: 'Carregados - Marketplace de Carros Elétricos',
	},
	{
		tagName: 'link',
		rel: 'canonical',
		href: 'https://carregados.com.br',
	},
]

export async function loader({ request }: LoaderFunctionArgs) {
	const ipCountry = request.headers.get("cf-ipcountry");
	const ipLongitude = request.headers.get("cf-iplongitude");
	const ipLatitude = request.headers.get("cf-iplatitude");

	const url = new URL(request.url);

	let longitude = -51.965332;
	let latitude = -16.741428;
	let zoomLevel = 5;

	if (ipCountry === "BR" && ipLongitude && ipLatitude) {
    	longitude = parseFloat(ipLongitude);
    	latitude = parseFloat(ipLatitude);
   		zoomLevel = 10;
	}

	const timings = makeTimings('index loader') // <-- 1. Setup Timings

	const allMakers = await time(
		() =>
			prisma.car.groupBy({
				by: ['maker'],
				where: {
					isInactive: {
						equals: true,
					},
				},
				_count: {
					maker: true,
				},
			}),
		{ timings, type: 'maker listings' },
	)

	invariantResponse(allMakers, 'allMakers not found', { status: 400 })

	const carListings = await time(
		() =>
			prisma.car.findMany({
				take: 6,
				select: {
					id: true,
					maker: true,
					model: true,
					fabricationYear: true,
					modelYear: true,
					state: true,
					city: true,
					price: true,
					version: true,
					odometer: true,
					url: true,
					isPaid: true,
					featuredAtMainPage: true,
					images: {
						select: {
							id: true,
						},
						where: {
							order: 0,
						},
					},
				},
				where: {
					isInactive: false,
					isRemoved: false,
				},
				orderBy: [
					{
						isPaid: 'desc',
					},
					{
						featuredAtMainPage: 'desc',
					},
					{
						createdAt: 'desc',
					},
				],
			}),
		{ timings, type: 'initial car listings' },
	)

	invariantResponse(carListings, 'carListings not found', { status: 400 })

	//count all cars
	const totalNumberOfCars = await time(
		() =>
			prisma.car.count({
				where: {
					isInactive: false,
					isRemoved: false,
				},
			}),
		{ timings, type: 'total number of cars' },
	)

	const allModels = await time(
		() =>
			prisma.car.groupBy({
				by: ['model'],
				where: {
					isInactive: false,
					isRemoved: false,
				},
				_count: {
					model: true,
				},
			}),
		{ timings, type: 'models listings' },
	)

	invariantResponse(allModels, 'allModels not found', { status: 400 })

	let allStates = await time(
		() =>
			prisma.car.groupBy({
				where: {
					isInactive: false,
					isRemoved: false,
				},
				by: ['state'],
				_count: {
					state: true,
				},
			}),
		{ timings, type: 'states listings' },
	)

	//capitalize first letter of each state, sort alphabetically and uppercase betweem parentheses
	allStates = allStates.map((state: any) => {
		return {
			state: `${state.state[0].toUpperCase()}${state.state.slice(1)}`,
			_count: state._count,
		}
	})

	invariantResponse(allStates, 'allStates not found', { status: 400 })

	//fill comboboxes
	const makers = allMakers.map((maker: any) => {
		return {
			value: `${maker.maker}`,
			label: `${maker.maker}`,
			count: `(${maker._count.maker})`,
			title: 'Marca',
		}
	})

	//add all to makers
	makers.unshift({
		value: '',
		label: 'Todos',
		count: '',
		title: 'Marca',
	})

	const models = allModels.map((model: any) => {
		return {
			value: `${model.model}`,
			label: `${model.model}`,
			count: `(${model._count.model})`,
			title: 'Modelo',
		}
	})

	//add all to models
	models.unshift({
		value: '',
		label: 'Todos',
		count: '',
		title: 'Modelo',
	})

	const states = allStates.map((state: any) => {
		return {
			value: `${state.state}`,
			label: `${state.state}`,
			count: `(${state._count.state})`,
			title: 'Localização',
		}
	})

	//add all to states
	states.unshift({
		value: '',
		label: 'Todos',
		count: '',
		title: 'Localização',
	})

	const locationCount = await time(
		() => prisma.location.count({
			where: {
				isInactive: false,
			},
		}),
		{ timings, type: 'location count' },	
	)

	return json(
		{ carListings, makers, models, states, totalNumberOfCars, longitude, latitude, zoomLevel, locationCount },
		{
			headers: {
				'Cache-Control': 'private, max-age=3600',
				'Server-Timing': timings.toString(),
			},
		},
	)
}

export async function action({ request }: ActionFunctionArgs) {
	const formData = await request.formData()
	const marca = formData.get('marca')
	const modelo = formData.get('modelo')
	const localizacao = formData.get('localizacao')

	let query = new URLSearchParams()
	if (marca) query.append('marca', marca.toString())
	if (modelo) query.append('modelo', modelo.toString())
	if (localizacao) query.append('estado', localizacao.toString())

	if (query) {
		return redirect(`/carros?${query}`)
	} else {
		return redirect(`/carros`)
	}
}

export default function Index() {
	const { carListings, makers, models, states, longitude, latitude, zoomLevel, locationCount } =
		useLoaderData<typeof loader>()
	const fetcher = useFetcher()
	const user = useOptionalUser()

	const [selectedMaker, setSelectedMaker] = React.useState('')
	const [selectedModel, setSelectedModel] = React.useState('')
	const [selectedState, setSelectedState] = React.useState('')
	const [filteredMaker, setFilteredMaker] = React.useState(makers)
	const [filteredModel, setFilteredModel] = React.useState(models)
	const [filteredState, setFilteredState] = React.useState(states)

	interface FetcherDataType {
		makers: any
		models: any
		states: any
	}

	React.useEffect(() => {
		fetcher.load(
			`/resources/options?maker=${selectedMaker}&model=${selectedModel}&state=${selectedState}`,
		)
	}, [selectedMaker, selectedModel, selectedState])

	React.useEffect(() => {
		if (
			typeof fetcher.data === 'object' &&
			fetcher.data !== null &&
			'makers' in fetcher.data &&
			'models' in fetcher.data &&
			'states' in fetcher.data
		) {
			const data = fetcher.data as FetcherDataType
			setFilteredMaker(data.makers)
			setFilteredModel(data.models)
			setFilteredState(data.states)
		}
	}, [fetcher.data])


	return (
		<>
			<main>
				{/* Hero Section */}
				<Hero
					filteredMaker={filteredMaker}
					filteredModel={filteredModel}
					filteredState={filteredState}
					selectedMaker={selectedMaker}
					setSelectedMaker={setSelectedMaker}
					selectedModel={selectedModel}
					setSelectedModel={setSelectedModel}
					selectedState={selectedState}
					setSelectedState={setSelectedState}
				/>

				{/* Brands Section
        <Brands totalNumberOfCars={totalNumberOfCars} /> */}

				{/* Featured Cars Section */}
				<FeaturedListings carListings={carListings} />

				{/* Benefits Section */}
				<Benefits user={user?.username} />

				{/* Testimonials Section */}
				<Testimonials user={user?.username} />

				{/* Pricing Section */}
				<PricingTable user={user?.username} />

				{/* FAQ Section */}
				<FAQ />

				{/* Map Section */}
				<MapMain longitude={longitude.toString()} latitude={latitude.toString()} zoomLevel={zoomLevel} locationCount={locationCount} />

				{/* Route Planner Section */}
				<RoutePlanner />

				{/* Articles Section */}
				<Articles user={user?.username} />
			</main>
		</>
	)
}

export const handle: SEOHandle = {
	//@ts-ignore
	getSitemapEntries: serverOnly$(async (request) => {
		const lastUpdatedOfAllCars = await prisma.car.findMany({
			select: {
				updatedAt: true,
			},
			orderBy: {
				updatedAt: 'desc',
			},
			take: 1,
		})

		return [
			{
				route: '',
				priority: 0.7,
				changefreq: 'daily',
				lastmod:
					lastUpdatedOfAllCars &&
					lastUpdatedOfAllCars[0]?.updatedAt.toISOString(),
			},
		]
	}),
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
