import { Link } from '@remix-run/react';
import { useEffect } from 'react';
import { setupScrollAnimations } from '#app/utils/animations.ts';

const Benefits = ({ user }: { user?: string }) => {
  useEffect(() => {
    setupScrollAnimations();
  }, []);

  const benefits = [
    {
      title: "Visibilidade máxima",
      description: "Seu veículo elétrico será visto por milhares de compradores qualificados interessados especificamente em carros elétricos.",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" /><path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" /></svg>
    },
    {
      title: "Processo simplificado",
      description: "Nossa plataforma torna simples listar, gerenciar e vender seu veículo elétrico com ferramentas intuitivas e suporte especializado.",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" /></svg>
    },
    {
      title: "Compradores qualificados",
      description: "Conectamos você diretamente com compradores sérios e informados sobre veículos elétricos, reduzindo perguntas básicas e negociações improdutivas.",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" /></svg>
    },
    {
      title: "Recursos especializados",
      description: "Oferecemos recursos específicos para veículos elétricos, destacando autonomia, capacidade de bateria e outras informações cruciais para compradores de EVs.",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z" /></svg>
    },
    {
      title: "Valorização adequada",
      description: "Ajudamos você a precificar corretamente seu veículo elétrico, considerando fatores específicos como condição da bateria e atualizações de software.",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
    },
    {
      title: "Suporte especializado",
      description: "Nossa equipe especializada em veículos elétricos está sempre disponível para responder perguntas e ajudar durante todo o processo de venda.",
      icon: <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2"><path strokeLinecap="round" strokeLinejoin="round" d="M18.364 5.636l-3.536 3.536m0 5.656l3.536 3.536M9.172 9.172L5.636 5.636m3.536 9.192l-3.536 3.536M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-5 0a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
    }
  ];

  return (
    <section id="benefits" className="section relative">
      <div className="max-w-7xl mx-auto">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-[10%] left-[5%] w-72 h-72 bg-primary/5 rounded-full blur-3xl"></div>
        <div className="absolute bottom-[10%] right-[5%] w-72 h-72 bg-blue-400/5 rounded-full blur-3xl"></div>
      </div>
      
      <div className="relative z-10 mb-16 text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
          <span>Por que anunciar com a Carregados</span>
        </div>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Benefícios exclusivos para vendedores</h2>
        <p className="text-foreground/70 max-w-2xl mx-auto">
          Descubra como nossa plataforma especializada em veículos elétricos pode ajudar você a vender seu carro de forma mais rápida e pelo melhor preço.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10">
        {benefits.map((benefit, index) => (
          <div key={index} className="animate-on-scroll flex flex-col">
            <div className="w-12 h-12 bg-primary/10 rounded-lg flex items-center justify-center text-primary mb-4">
              {benefit.icon}
            </div>
            <h3 className="text-xl font-bold mb-2">{benefit.title}</h3>
            <p className="text-foreground/70 flex-grow">{benefit.description}</p>
          </div>
        ))}
      </div>

      <div className="animate-on-scroll mt-16 p-6 bg-primary/5 rounded-2xl">
        <div className="flex flex-col md:flex-row items-center justify-between gap-8">
          <div className="flex-1">
            <h3 className="text-2xl font-bold mb-3">Pronto para vender seu veículo elétrico?</h3>
            <p className="text-foreground/70">Comece agora e alcance milhares de potenciais compradores interessados em veículos elétricos.</p>
          </div>
          <Link
            prefetch="intent"
            to={"/anuncios"} 
            className="px-6 py-3 rounded-full bg-primary text-white font-medium transition-all hover:bg-primary/90 shadow-md hover:shadow-lg flex-shrink-0"
          >
            Começar Agora
          </Link>
        </div>
      </div>
      </div>
    </section>
  );
};

export default Benefits;