import { Link } from '@remix-run/react';
import { ArrowRight } from 'lucide-react';
import { useOptionalUser } from '#app/utils/user';
import { NormalCarListing } from './normal-car-listing';

interface FeaturedListingsProps {
  carListings: any[];
}

export function FeaturedListings({ carListings }: FeaturedListingsProps) {
  const user = useOptionalUser();

  return (
    <section id="listings" className="section px-2 sm:px-12 bg-gray-50 dark:bg-gray-900/50">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-20 left-10 w-72 h-72 bg-blue-100 rounded-full opacity-30 blur-3xl"></div>
        <div className="absolute bottom-20 right-10 w-72 h-72 bg-primary/10 rounded-full opacity-30 blur-3xl"></div>
      </div>
      
      <div className="relative z-10 mb-12 text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm mb-4">
          <span>Veículos em Destaque</span>
        </div>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">Carros elétricos selecionados</h2>
        <p className="text-foreground/70 max-w-2xl mx-auto">
          Descubra os melhores veículos elétricos disponíveis em nossa plataforma, selecionados com base em qualidade, desempenho e valor.
        </p>
      </div>

      <div className="mx-auto max-w-6xl sm:px-6 lg:px-8 w-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
          {carListings.map((carListing) => (
            <div key={carListing.id}>
              <Link
                prefetch="intent"
                to={`/carros/${carListing.url}${carListing.id}`}
                className="w-full md:w-fit"
              >
                <NormalCarListing data={carListing} user={user} />
              </Link>
            </div>
          ))}
        </div>

        <div className="mt-12 text-center">
          <Link 
            to="/carros" 
            className="inline-flex items-center justify-center px-6 py-3 rounded-full bg-primary text-white font-medium transition-all hover:bg-primary/90 shadow-md hover:shadow-lg"
          >
            Ver Todos os Veículos
            <ArrowRight className="ml-2 h-4 w-4" />
          </Link>
        </div>
      </div>
    </section>
  );
} 