import { Link } from '@remix-run/react';
import { Star } from 'lucide-react';
import { useEffect } from 'react';
import { setupScrollAnimations } from '#app/utils/animations';

interface TestimonialProps {
  quote: string;
  author: string;
  role: string;
  avatar: string;
  rating: number;
}

const Testimonial = ({ quote, author, role, avatar, rating }: TestimonialProps) => {
  return (
    <div className="animate-on-scroll bg-white dark:bg-gray-900 p-6 rounded-xl shadow-lg border border-gray-100 dark:border-gray-800">
      <div className="flex gap-1 mb-4">
        {Array.from({ length: 5 }).map((_, i) => (
          <Star 
            key={i} 
            size={16} 
            className={i < rating ? "fill-yellow-400 text-yellow-400" : "text-gray-300"} 
          />
        ))}
      </div>
      <p className="text-foreground/80 mb-6 italic">"{quote}"</p>
      <div className="flex items-center">
        <img 
          src={avatar} 
          alt={author} 
          className="w-12 h-12 rounded-full mr-4 object-cover" 
        />
        <div>
          <h4 className="font-bold">{author}</h4>
          <p className="text-sm text-foreground/60">{role}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = ({ user }: { user?: string }) => {
  useEffect(() => {
    setupScrollAnimations();
  }, []);

  const testimonials = [
    {
      quote: "Vendi meu Tesla Model 3 em apenas 3 dias através da Carregados. A visibilidade foi impressionante e o processo super simples!",
      author: "Carlos Silva",
      role: "Proprietário, Tesla Model 3",
      avatar: "https://randomuser.me/api/portraits/men/32.jpg",
      rating: 5
    },
    {
      quote: "Encontrei compradores realmente interessados na plataforma Carregados. Vendi meu carro em menos de uma semana por um ótimo preço!",
      author: "Mariana Costa",
      role: "Proprietária, Volvo EX30",
      avatar: "https://randomuser.me/api/portraits/women/44.jpg",
      rating: 5
    },
    {
      quote: "A equipe da Carregados me ajudou a definir o preço ideal para meu Dolphin, considerando todos os extras e a condição da bateria.",
      author: "Pedro Almeida",
      role: "Proprietário, BYD Dolphin Plus",
      avatar: "https://randomuser.me/api/portraits/men/22.jpg",
      rating: 4
    },
    {
      quote: "Destaque na página inicial trouxe mais de 30 interessados na primeira semana. Processo de venda rápido e sem dor de cabeça!",
      author: "Juliana Mendes",
      role: "Proprietária, Audi e-tron",
      avatar: "https://randomuser.me/api/portraits/women/68.jpg",
      rating: 5
    }
  ];

  return (
    <section id="testimonials" className="section bg-gray-50 dark:bg-gray-900/50 relative">
      <div className="max-w-7xl mx-auto">
      {/* Background decorations */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-[10%] left-[5%] w-72 h-72 bg-primary/5 rounded-full blur-3xl"></div>
        <div className="absolute bottom-[10%] right-[5%] w-72 h-72 bg-blue-400/5 rounded-full blur-3xl"></div>
      </div>
      
      <div className="relative z-10 mb-16 text-center">
        <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-4">
          <span>Depoimentos</span>
        </div>
        <h2 className="text-3xl md:text-4xl font-bold mb-4">O que nossos clientes dizem</h2>
        <p className="text-foreground/70 max-w-2xl mx-auto">
          Veja como a Carregados tem ajudado proprietários e revendedores a comercializar veículos elétricos com sucesso.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {testimonials.map((testimonial, index) => (
          <Testimonial
            key={index}
            quote={testimonial.quote}
            author={testimonial.author}
            role={testimonial.role}
            avatar={testimonial.avatar}
            rating={testimonial.rating}
          />
        ))}
      </div>

      <div className="animate-on-scroll mt-12 p-8 bg-primary/5 rounded-2xl text-center">
        <h3 className="text-2xl font-bold mb-4">Junte-se a milhares de vendedores satisfeitos</h3>
        <p className="text-foreground/70 mb-6 max-w-2xl mx-auto">
          Comece hoje mesmo e descubra como a Carregados pode transformar sua experiência de venda de veículos elétricos.
        </p>
        <Link
          prefetch="intent"
          to={"/anuncios"} 
          className="inline-flex items-center justify-center px-6 py-3 rounded-full bg-primary text-white font-medium transition-all hover:bg-primary/90 shadow-md hover:shadow-lg"
        >
          Criar Minha Conta Agora
        </Link>
      </div>
      </div>
    </section>
  );
};

export default Testimonials;