import { Form } from "@remix-run/react";

import { ComboboxWithData } from "#app/components/comboboxwithdata.tsx";
import { Button } from "#app/components/ui/button.tsx";
import { Label } from "#app/components/ui/label.tsx";

export function Selectors({
  makers,
  models,
  states,
  selectedMaker,
  onMakerChange,
  selectedModel,
  onModelChange,
  selectedState,
  onStateChange,
}: any) {
  return (
    <div className="w-full">
      <Form className="flex flex-wrap md:flex-nowrap items-end gap-2" method="post">
        {/* Marca */}
        <div className="flex flex-col w-full md:w-auto">
          <Label className="text-xs font-medium text-gray-600 mb-1" htmlFor="marca">
            Marca
          </Label>
          <ComboboxWithData
            id="marca"
            fullData={makers}
            selectedValue={selectedMaker}
            onValueChange={onMakerChange}
            ariaLabel="Selecione a marca"
          />
          <input type="hidden" name="marca" value={selectedMaker} />
        </div>
        
        {/* Modelo */}
        <div className="flex flex-col w-full md:w-auto">
          <Label className="text-xs font-medium text-gray-600 mb-1" htmlFor="modelo">
            Modelo
          </Label>
          <ComboboxWithData
            id="modelo"
            fullData={models}
            selectedValue={selectedModel}
            onValueChange={onModelChange}
            ariaLabel="Selecione o modelo"
          />
          <input type="hidden" name="modelo" value={selectedModel} />
        </div>
        
        {/* Localização */}
        <div className="flex flex-col w-full md:w-auto">
          <Label className="text-xs font-medium text-gray-600 mb-1" htmlFor="localização">
            Localização
          </Label>
          <ComboboxWithData
            id="localização"
            fullData={states}
            selectedValue={selectedState}
            onValueChange={onStateChange}
            ariaLabel="Selecione a localização"
          />
          <input type="hidden" name="localizacao" value={selectedState} />
        </div>

        <div className="flex w-full md:ml-1">
          <Button
            type="submit"
            id="enviar"
            className="w-full px-6 py-2.5 bg-primary hover:bg-primary/90 text-white font-medium rounded-md"
          >
            Buscar
          </Button>
        </div>
      </Form>
    </div>
  );
}
