import { Link } from '@remix-run/react'
import { MapPin, ZoomIn } from 'lucide-react'
import { ClientOnly } from 'remix-utils/client-only'
import Mapa from '#app/components/mapa-inicial.jsx'
import { Button } from '#app/components/ui/button.tsx'
import { Skeleton } from '#app/components/ui/skeleton'
export function MapMain({
	longitude,
	latitude,
	zoomLevel,
	locationCount,
}: {
	longitude: string | null
	latitude: string | null
	zoomLevel: number
	locationCount: number
}) {
	return (
		<section
			id="mapa"
			className="w-full bg-gradient-to-b from-gray-50 to-white px-4 py-20 dark:from-gray-950 dark:to-gray-900"
		>
			{/* Background decorations */}
			<div className="absolute inset-0 overflow-hidden">
				<div className="absolute -left-[5%] -top-[10%] h-72 w-72 rounded-full bg-primary/5 blur-3xl"></div>
				<div className="absolute -bottom-[10%] -right-[5%] h-72 w-72 rounded-full bg-blue-400/5 blur-3xl"></div>
			</div>

			<div className="relative z-10 mx-auto max-w-6xl">
				<div className="mb-12 text-center">
					<span className="inline-flex items-center rounded-full bg-primary/10 px-3 py-1 text-sm font-medium text-primary">
						Carregadores
					</span>
					<h2 className="mt-6 text-3xl font-bold tracking-tight text-primary dark:text-white sm:text-4xl">
						Encontre estações de recarga próximas
					</h2>
					<p className="mx-auto mt-4 max-w-2xl text-lg text-muted-foreground dark:text-gray-300">
						Localize facilmente estações de carregamento para veículos elétricos
						em todo o Brasil com nosso mapa interativo.
					</p>
				</div>

				<div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
					<div className="rounded-xl bg-white p-6 shadow-lg dark:bg-gray-800/50 lg:col-span-1">
						<h3 className="mb-4 text-xl font-bold text-gray-900 dark:text-white">
							Estações Populares
						</h3>
						<div className="space-y-4">
							{[
								{
									name: 'FEPASA',
									address: 'Praça Paul Harris, S/N, Poços de Caldas, MG',
									type: 'CCS, CHAdeMO',
									power: '50 kW',
								},
								{
									name: 'WEG - O Fazendeiro SolarMar (Wemob)',
									address: 'Rod. Régis Bittencourt, Km 385, Miracatu, SP',
									type: 'CCS, Tipo 2',
									power: '40 kW',
								},
								{
									name: 'Posto Buffon 34',
									address: 'BR-101, 5884, Osório, RS',
									type: 'CCS, CHAdeMO, Tipo 2',
									power: '60 kW',
								},
								{
									name: 'Pariquera Açu Graal Petropen',
									address: 'Rod. Régis Bittencourt, BR 116 - Km 461, Pariquera-Açu, SP',
									type: 'CCS, CHAdeMO, Tipo 2',
									power: '100 kW',
								},
							].map((station, idx) => (
								<div
									key={idx}
									className="flex w-full items-start border-b border-gray-100 pb-3 dark:border-gray-700 p-1 rounded-lg hover:bg-gray-50 transition-colors"
								>
									<MapPin
										className="mr-2 mt-1 flex-shrink-0 text-primary"
										size={18}
									/>
									<div className="w-full">
										<p className="text-sm font-medium dark:text-white">
											{station.name}
										</p>
										<p className="text-xs text-muted-foreground dark:text-gray-400">
											{station.address}
										</p>
										<div className="mt-1 flex justify-between w-full text-xs">
											<span className="dark:text-gray-300">{station.type}</span>
											<span className="font-medium text-primary">
												{station.power}
											</span>
										</div>
									</div>
								</div>
							))}
						</div>

						<div className="mt-6">
							<Link prefetch="intent" to="/mapa" className="block w-full">
								<Button className="w-full font-semibold">
									Ver todas as estações
								</Button>
							</Link>
						</div>
					</div>

					<div className="relative h-[510px] overflow-hidden rounded-xl shadow-lg lg:col-span-2">
						<div className="absolute inset-0 flex items-center justify-center bg-gray-200">
							<ClientOnly
								fallback={<Skeleton className="h-[510px] w-full rounded-lg" />}
							>
								{() => (
									<div id="container" className="h-[510px] w-full">
										<Mapa
											chargingStations={[]}
											favorites={[]}
											userLat={btoa(latitude?.toString() || '0')}
											userLon={btoa(longitude?.toString() || '0')}
											zoomLevel={zoomLevel}
										/>
									</div>
								)}
							</ClientOnly>
							<div className="absolute bottom-6 left-1/2 -translate-x-1/2 transform rounded-full bg-white px-6 py-3 text-sm font-medium shadow-lg dark:bg-gray-800 dark:text-white">
								<span className="hidden md:inline">{locationCount}+ estações de carregamento disponíveis</span>
								<span className="md:hidden">{locationCount}+ estações</span>
							</div>
						</div>
					</div>
				</div>

				<div className="mt-12 text-center">
					<Link prefetch="intent" to="/mapa" className="inline-block">
						<Button className="px-6 py-3 font-semibold shadow-md hover:shadow-lg">
							Explorar Mapa Completo
						</Button>
					</Link>
				</div>
			</div>
		</section>
	)
}
