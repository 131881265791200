import React, { useRef, useEffect, useState } from 'react'
import maplibregl from 'maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import { useFetcher, useNavigate } from '@remix-run/react'
import { ChevronUp, ChevronDown, Gauge } from 'lucide-react'
import { Protocol } from 'pmtiles'
import { createRoot } from 'react-dom/client'
import { Button } from '#app/components/ui/button.tsx'
import mapData from '#other/mapa.json'
import './custom-geocoder.css'
// import MapLibreGlDirections, {
// 	LoadingIndicatorControl,
// } from '@maplibre/maplibre-gl-directions'
// import { Separator } from '#app/components/ui/separator.tsx'
// import { Button, buttonVariants } from '#app/components/ui/button.tsx'
// import {
// 	Dialog,
// 	DialogContent,
// 	DialogDescription,
// 	DialogHeader,
// 	DialogTitle,
// 	DialogTrigger,
// } from '#app/components/ui/dialog'
// import { AutoComplete } from '#app/components/ui/autocomplete.tsx'
// import { Combobox } from './forms'

const decode = (encoded) => {
	const salt = 'bkWUdYqNoedWDz9RZhZL'
	const data = atob(encoded)
	const value = parseFloat(data.replace(salt, ''))
	return value
}

// class CustomFullscreenControl extends maplibregl.FullscreenControl {
// 	_requestFullscreen() {
// 		this._togglePseudoFullScreen()
// 	}
// 	_exitFullscreen() {
// 		this._togglePseudoFullScreen()
// 	}
// }

export default function Mapa({
	favorites,
	chargingStations,
	userLat,
	userLon,
	zoomLevel,
}) {
	// useEffect(() => {
	// 	let protocol = new Protocol()
	// 	maplibregl.addProtocol('pmtiles', protocol.tile)
	// 	return () => {
	// 		maplibregl.removeProtocol('pmtiles')
	// 	}
	// }, [])

	// class RouteControl {
	// 	onAdd(map) {
	// 		this.map = map
	// 		this.container = document.createElement('div')
	// 		this.container.className = 'maplibregl-ctrl maplibregl-ctrl-group'
	// 		this.button = document.createElement('button')
	// 		this.button.innerHTML = '<img src="/route.svg" />'
	// 		this.button.type = 'button'
	// 		this.button.title = 'Rota'
	// 		this.button.onclick = () => {
	// 			setRouteModalOpen(!routeModalOpen)
	// 		}
	// 		this.container.appendChild(this.button)
	// 		return this.container
	// 	}

	// 	onRemove() {
	// 		this.container.parentNode.removeChild(this.container)
	// 		this.map = undefined
	// 	}
	// }

	class RadioFilterControl {
		onAdd(map) {
			this._map = map
			this._container = document.createElement('div')
			this._container.className = 'maplibregl-ctrl'

			const container = this._container
			const root = createRoot(container)

			//current power_filter query string
			const searchParams = new URLSearchParams(window.location.search)
			const powerFilter = searchParams.get('power_filter')

			root.render(
				<RadioFilter
					currentPowerFilter={powerFilter}
					onChange={this.handleFilterChange}
				/>,
			)

			return this._container
		}

		handleFilterChange = (e) => {
			const searchParams = new URLSearchParams(window.location.search)
			if (!e.target.value) {
				searchParams.delete('power_filter')
			} else {
				searchParams.set('power_filter', e.target.value)
			}
			window.history.pushState(
				{},
				'',
				`${window.location.pathname}?${searchParams.toString()}`,
			)

			// Clear the loaded stations cache when filter changes
			setLoadedStations(new Map())

			// Trigger a new fetch by updating the filter state
			setFilter(e.target.value || 0)
		}

		onRemove() {
			this._container.parentNode.removeChild(this._container)
			this._map = undefined
		}
	}

	const mapContainer = useRef(null)
	const map = useRef(null)
	const [lng] = useState(decode(userLon))
	const [lat] = useState(decode(userLat))
	const [zoom] = useState(zoomLevel)
	const fetcher = useFetcher()
	const navigate = useNavigate()
	const [filter, setFilter] = useState(0)
	const [routeModalOpen, setRouteModalOpen] = useState()
	// const departureFetcher = useFetcher()
	// const arrivalFetcher = useFetcher()

	//Departure
	// const [isDepartureDisabled, setDepartureDisabled] = useState(false)
	// const [departureValue, setDepartureValue] = useState()
	// const [departureQuery, setDepartureQuery] = useState()
	// const [departureOptions, setDepartureOptions] = useState([])

	//Arrival
	// const [isArrivalDisabled, setArrivalDisabled] = useState(false)
	// const [arrivalValue, setArrivalValue] = useState()
	// const [arrivalQuery, setArrivalQuery] = useState()

	const [loadedStations, setLoadedStations] = useState(new Map())
	const maxStations = 500 // Maximum stations to keep in memory

	const isStationInBounds = (station, bounds) => {
		const lat = decode(station.latitude)
		const lng = decode(station.longitude)
		return (
			lng >= bounds._sw.lng &&
			lng <= bounds._ne.lng &&
			lat >= bounds._sw.lat &&
			lat <= bounds._ne.lat
		)
	}

	const RadioFilter = ({ onChange, currentPowerFilter }) => {
		const [isMinimized, setIsMinimized] = useState(!currentPowerFilter)
		const [selectedFilter, setSelectedFilter] = useState(
			currentPowerFilter || '',
		)

		// Update local state when currentPowerFilter changes from URL
		useEffect(() => {
			setSelectedFilter(currentPowerFilter || '')
		}, [currentPowerFilter])

		// Update global filter state when selectedFilter changes
		useEffect(() => {
			if (selectedFilter) {
				setFilter(selectedFilter)
			} else {
				setFilter(0) // Default to "Todas"
			}
		}, [selectedFilter])

		const handleFilterChange = (e) => {
			setSelectedFilter(e.target.value)
			onChange(e)
		}

		return (
			<div className="power-filter-container">
				<div
					className="power-filter-header"
					onClick={() => setIsMinimized(!isMinimized)}
				>
					<Gauge className="power-filter-icon-left" />
					<span className="power-filter-title">Potência Média</span>
					{isMinimized ? (
						<ChevronDown className="power-filter-icon-right" />
					) : (
						<ChevronUp className="power-filter-icon-right" />
					)}
				</div>
				{!isMinimized && (
					<form className="power-filter-form">
						<div className="power-filter-option">
							<input
								type="radio"
								id="todos"
								name="power_filter"
								value=""
								checked={selectedFilter !== '20' && selectedFilter !== '50'}
								onChange={handleFilterChange}
								className="power-filter-radio"
							/>
							<label className="power-filter-label" htmlFor="todos">
								Todas
							</label>
						</div>
						<div className="power-filter-option">
							<input
								type="radio"
								id="20kw"
								name="power_filter"
								value="20"
								checked={selectedFilter === '20'}
								onChange={handleFilterChange}
								className="power-filter-radio"
							/>
							<label className="power-filter-label" htmlFor="20kw">
								&gt; 20 kW
							</label>
						</div>
						<div className="power-filter-option">
							<input
								type="radio"
								id="50kw"
								name="power_filter"
								value="50"
								checked={selectedFilter === '50'}
								onChange={handleFilterChange}
								className="power-filter-radio"
							/>
							<label className="power-filter-label" htmlFor="50kw">
								&gt; 50 kW
							</label>
						</div>
					</form>
				)}
			</div>
		)
	}

	const iconTypeToColor = {
		AR: 'vermelho',
		BR: 'vermelho',
		YR: 'vermelho',
		GR: 'vermelho',
		A: 'amarelo',
		B: 'amarelo',
		H: 'amarelo',
		Y: 'azul',
		G: 'verde',
	}

	const openLink = (url, id) => {
		const searchParams = new URLSearchParams(window.location.search)

		navigate(`/estacoes/${url}${id}?${searchParams.toString()}`, {
			preventScrollReset: true,
			state: { history: `/estacoes?${searchParams.toString()}` },
		})
	}

	useEffect(() => {
		if (map.current) return // stops map from intializing more than once

		map.current = new maplibregl.Map({
			container: mapContainer.current,
			style: mapData,
			// style:
			// 	'https://api.maptiler.com/maps/11347c1d-e020-4b1b-95be-1e11299e4b94/style.json?key=DBiR6978mpKK9VStp8FJ',
			center: [lng, lat],
			zoom: zoom,
			attributionControl: false,
			fadeDuration: 100,
		})

		map.current.on('load', async () => {
			const features = chargingStations.map((station) => ({
				type: 'Feature',
				properties: {
					id: station.id,
					url: station.url,
					icon: favorites.includes(station.id)
						? 'roxo'
						: iconTypeToColor[station.icon_type],
				},
				geometry: {
					type: 'Point',
					coordinates: [decode(station.longitude), decode(station.latitude)],
				},
			}))

			const bounds = map.current.getBounds()

			const nlat = bounds._ne.lat.toString()
			const nlng = bounds._ne.lng.toString()
			const slat = bounds._sw.lat.toString()
			const slng = bounds._sw.lng.toString()

			// get current power_filter query string - if it exists
			const searchParams = new URLSearchParams(window.location.search)
			const powerFilter = searchParams.get('power_filter')
			let urlToLoad = `/resources/bb-client?nlat=${nlat}&nlng=${nlng}&slat=${slat}&slng=${slng}`
			if (powerFilter) {
				urlToLoad = `/resources/bb-client?nlat=${nlat}&nlng=${nlng}&slat=${slat}&slng=${slng}&power_filter=${powerFilter}`
			}

			fetcher.load(urlToLoad)

			const colors = ['verde', 'roxo', 'amarelo', 'azul', 'vermelho']

			colors.forEach(async (color) => {
				let image = await map.current.loadImage(`/${color}.png`)
				await map.current.addImage(color, image.data)
				if (!map.current.hasImage(color))
					map.current.addImage(color, image.data)
			})

			map.current.addSource('stations', {
				type: 'geojson',
				data: {
					type: 'FeatureCollection',
					features: features,
				},
			})

			map.current.addLayer({
				id: 'stations',
				type: 'symbol',
				source: 'stations',
				layout: {
					'icon-image': '{icon}',
					'icon-size': 0.7,
					'icon-allow-overlap': true,
					'text-allow-overlap': true,
					'icon-ignore-placement': true,
					'text-ignore-placement': true,
					'icon-anchor': 'bottom',
				},
				filter: ['!', ['in', ['get', 'icon'], ['literal', ['azul', 'roxo']]]],
			})

			map.current.addLayer({
				id: 'stations-azul',
				type: 'symbol',
				source: 'stations',
				layout: {
					'icon-image': '{icon}',
					'icon-size': 0.7,
					'icon-allow-overlap': true,
					'text-allow-overlap': true,
					'icon-ignore-placement': true,
					'text-ignore-placement': true,
					'icon-anchor': 'bottom',
				},
				filter: ['==', ['get', 'icon'], 'azul'],
			})

			map.current.addLayer({
				id: 'stations-roxo',
				type: 'symbol',
				source: 'stations',
				layout: {
					'icon-image': '{icon}',
					'icon-size': 0.7,
					'icon-allow-overlap': true,
					'text-allow-overlap': true,
					'icon-ignore-placement': true,
					'text-ignore-placement': true,
					'icon-anchor': 'bottom',
				},
				filter: ['==', ['get', 'icon'], 'roxo'],
			})

			map.current.addControl(
				new maplibregl.GeolocateControl({
					positionOptions: {
						enableHighAccuracy: true,
					},
					trackUserLocation: true,
				}),
			)

			map.current.addControl(new maplibregl.AttributionControl(), 'bottom-left')
			map.current.addControl(new maplibregl.NavigationControl(), 'top-right')
			//@ts-ignore
			// map.current.addControl(new CustomFullscreenControl())
			map.current.addControl(new RadioFilterControl(), 'top-left')

			// map.current.on('click', 'stations', (e) => {
			// 	if (e.features.length > 0) {
			// 		const { id, url } = e.features[0].properties
			// 		openLink(url, id)
			// 	}
			// })

			// map.current.on('click', 'stations-azul', (e) => {
			// 	if (e.features.length > 0) {
			// 		const { id, url } = e.features[0].properties
			// 		openLink(url, id)
			// 	}
			// })

			// map.current.on('click', 'stations-roxo', (e) => {
			// 	if (e.features.length > 0) {
			// 		const { id, url } = e.features[0].properties
			// 		openLink(url, id)
			// 	}
			// })
		})

		// // Change the cursor to a pointer when the mouse is over the places layer.
		// map.current.on('mouseenter', 'stations', () => {
		// 	map.current.getCanvas().style.cursor = 'pointer'
		// })

		// // Change it back to a pointer when it leaves.
		// map.current.on('mouseleave', 'stations', () => {
		// 	map.current.getCanvas().style.cursor = ''
		// })

		// // Change the cursor to a pointer when the mouse is over the places layer.
		// map.current.on('mouseenter', 'stations-azul', () => {
		// 	map.current.getCanvas().style.cursor = 'pointer'
		// })

		// // Change it back to a pointer when it leaves.
		// map.current.on('mouseleave', 'stations-azul', () => {
		// 	map.current.getCanvas().style.cursor = ''
		// })

		// map.current.on('mouseenter', 'stations-roxo', () => {
		// 	map.current.getCanvas().style.cursor = 'pointer'
		// })

		// map.current.on('mouseleave', 'stations-roxo', () => {
		// 	map.current.getCanvas().style.cursor = ''
		// })

		map.current.on('moveend', () => {
			const bounds = map.current.getBounds()

			const nlat = bounds._ne.lat.toString()
			const nlng = bounds._ne.lng.toString()
			const slat = bounds._sw.lat.toString()
			const slng = bounds._sw.lng.toString()

			// get current power_filter query string - if it exists
			const searchParams = new URLSearchParams(window.location.search)
			const powerFilter = searchParams.get('power_filter')

			fetcher.load(
				`/resources/bb-client?nlat=${nlat}&nlng=${nlng}&slat=${slat}&slng=${slng}&power_filter=${powerFilter}`,
			)
		})
	}, [lng, lat, zoom])

	useEffect(() => {
		map.current.flyTo({
			center: [decode(userLon), decode(userLat)],
			zoom: zoomLevel,
		})
	}, [userLat, userLon, zoomLevel])

	useEffect(() => {
		if (!map.current) return

		const bounds = map.current.getBounds()
		const nlat = bounds._ne.lat.toString()
		const nlng = bounds._ne.lng.toString()
		const slat = bounds._sw.lat.toString()
		const slng = bounds._sw.lng.toString()

		// get current power_filter query string
		const searchParams = new URLSearchParams(window.location.search)
		const powerFilter = searchParams.get('power_filter')

		// Clear the loaded stations when filter changes
		setLoadedStations(new Map())

		fetcher.load(
			`/resources/bb-client?nlat=${nlat}&nlng=${nlng}&slat=${slat}&slng=${slng}&power_filter=${powerFilter}`,
		)
	}, [filter])

	useEffect(() => {
		if (fetcher.data) {
			const newStations = new Map()

			// Process new stations
			fetcher.data.stations.forEach((station) => {
				const stationId = station.id

				// Only add if we don't already have this station
				if (!loadedStations.has(stationId)) {
					newStations.set(stationId, station)
				}
			})

			// Merge with existing stations, respecting the maximum limit
			const mergedStations = new Map([...loadedStations, ...newStations])

			// If we exceed the maximum, remove oldest stations that aren't in current view
			if (mergedStations.size > maxStations) {
				const bounds = map.current.getBounds()
				const stationsArray = Array.from(mergedStations.entries())

				// Sort by keeping stations in current view
				stationsArray.sort(([, a], [, b]) => {
					const aInBounds = isStationInBounds(a, bounds)
					const bInBounds = isStationInBounds(b, bounds)

					if (aInBounds && !bInBounds) return -1
					if (!aInBounds && bInBounds) return 1
					return 0
				})

				// Keep only the maximum allowed stations
				const trimmedStations = new Map(stationsArray.slice(0, maxStations))
				setLoadedStations(trimmedStations)
			} else {
				setLoadedStations(mergedStations)
			}

			// Create features from all loaded stations
			const features = Array.from(mergedStations.values()).map((station) => ({
				type: 'Feature',
				properties: {
					id: station.id,
					url: station.url,
					icon: favorites.includes(station.id)
						? 'roxo'
						: iconTypeToColor[station.icon_type],
				},
				geometry: {
					type: 'Point',
					coordinates: [decode(station.longitude), decode(station.latitude)],
				},
			}))

			// Update the map source
			const source = map.current.getSource('stations')
			if (source) {
				source.setData({
					type: 'FeatureCollection',
					features: features,
				})
			}
		}
	}, [fetcher.data])

	return (
		<div id="full" className="h-full w-full overflow-hidden rounded-lg">
			<div className="relative h-full w-full rounded-lg">
				<div ref={mapContainer} className="absolute h-full w-full" />
				{fetcher.state !== 'idle' && (
					<div className="absolute bottom-0 right-0 flex items-start justify-start text-[#f58b14]">
						<svg
							className="h-5 w-5 animate-spin"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
						>
							<circle
								className="opacity-25"
								cx={12}
								cy={12}
								r={10}
								stroke="currentColor"
								strokeWidth={4}
							/>
							<path
								className="opacity-75"
								fill="currentColor"
								d="M4 12a8 8 0 0 1 8-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 0 1 4 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
							/>
						</svg>
					</div>
				)}
				{/* <div className="absolute bottom-0 left-20 flex items-start justify-start text-lg text-green-500">
				{fetcher.data && fetcher.data.stations.length > 0 ? (
					<p>{fetcher.data.stations.length} estações</p>
				) : (
					<p>0 estações</p>
				)}
			</div> */}
			</div>
		</div>
		// </>
	)
}
