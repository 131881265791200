import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Button } from "#app/components/ui/button.tsx";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "#app/components/ui/command.tsx";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "#app/components/ui/popover.tsx";
import { cn } from "#app/utils/misc.tsx";

export function ComboboxWithData({
  fullData,
  selectedValue,
  onValueChange,
  ariaLabel,
}: any) {
  const [open, setOpen] = React.useState(false);

  const title = fullData[0].title;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full md:w-[200px] justify-between text-foreground"
          aria-label={ariaLabel}
        >
          {selectedValue
            ? fullData.find((data: any) => data.value === selectedValue)?.label
            : `Todos`}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="max-h-56 w-[300px] overflow-y-auto p-0">
        <Command>
          <CommandInput placeholder={`Procurar ${title.toLowerCase()}`} />
          <CommandEmpty>Nenhum resultado encontrado.</CommandEmpty>
          <CommandGroup>
            {fullData.map((data: any) => (
              <CommandItem
                key={data.value}
                value={data.value}
                onSelect={(currentValue) => {
                  onValueChange(
                    currentValue === selectedValue ? "" : currentValue,
                  );
                  setOpen(false);
                }}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    selectedValue === data.value ? "opacity-100" : "opacity-0",
                  )}
                />
                {data.label}
                <span className="ml-1 underline">{data.count}</span>
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
