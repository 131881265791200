import { Link } from '@remix-run/react';
import { MapPin, Search } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import Tilt from 'react-parallax-tilt';

import { useOptionalUser } from '#app/utils/user';
import { Selectors } from './selectors';

interface HeroProps {
  filteredMaker: any[];
  filteredModel: any[];
  filteredState: any[];
  selectedMaker: string;
  setSelectedMaker: (value: string) => void;
  selectedModel: string;
  setSelectedModel: (value: string) => void;
  selectedState: string;
  setSelectedState: (value: string) => void;
}

const Hero = ({
  filteredMaker,
  filteredModel,
  filteredState,
  selectedMaker,
  setSelectedMaker,
  selectedModel,
  setSelectedModel,
  selectedState,
  setSelectedState,
}: HeroProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const heroRef = useRef<HTMLDivElement>(null);
  const user = useOptionalUser();

  useEffect(() => {
    setIsVisible(true);
    
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
      rootMargin: '0px'
    });

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  const scrollToServices = () => {
    const servicesSection = document.querySelector('#services');
    if (servicesSection) {
      servicesSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section 
      id="home" 
      ref={heroRef} 
      className="relative min-h-screen flex items-center pb-20 md:pb-28 justify-center pt-10 md:pt-16"
    >
      {/* Background gradients */}
      <div className="absolute inset-0">
        {/* Primary gradient blob */}
        <div 
          className="absolute top-[5%] right-[10%] w-72 h-72 rounded-full bg-gradient-to-br from-primary/40 to-primary/10"
          style={{ filter: 'blur(60px)' }}
        ></div>
        
        {/* Secondary gradient blob */}
        <div 
          className="absolute bottom-[10%] left-[5%] w-96 h-96 rounded-full bg-gradient-to-tr from-blue-500/30 to-indigo-500/10"
          style={{ filter: 'blur(70px)' }}
        ></div>
        
        {/* Accent gradient blob */}
        <div 
          className="absolute top-[40%] left-[20%] w-64 h-64 rounded-full bg-gradient-to-r from-green-400/20 to-teal-300/10"
          style={{ filter: 'blur(55px)' }}
        ></div>
      </div>

      <div className="container relative z-10 px-6 md:px-12 max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-16 items-center">
          {/* Text content */}
          <div className={`space-y-8 ${isVisible ? 'animate-fade-in' : 'opacity-0'}`}>
            <div className="space-y-2">
              <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary text-sm font-medium mb-6">
                <span>Revolucione sua jornada elétrica</span>
              </div>
              <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold leading-tight">
                O <span className="text-primary">futuro da mobilidade</span> está aqui!
              </h1>
              <p className="text-lg mt-6 text-foreground/80 max-w-lg">
                Descubra o futuro da mobilidade com o maior marketplace de carros elétricos do Brasil.
              </p>
            </div>

            <div className="flex flex-col sm:flex-row gap-4 pt-4">
              <Link 
                to="/carros"
                prefetch="intent"
                className="inline-flex items-center justify-center px-6 py-3 rounded-full bg-primary text-white font-medium transition-all hover:bg-primary/90 shadow-lg shadow-primary/20"
              >
                Carros Elétricos à Venda
                <Search className="ml-2 h-4 w-4" />
              </Link>
              <Link
                to={'/mapa'}
                prefetch="intent"
                className="inline-flex items-center justify-center px-6 py-3 rounded-full border border-gray-200 bg-white/80 backdrop-blur-sm text-foreground font-medium hover:bg-white/90 transition-all"
              >
                Mapa de Estações
                <MapPin className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>

          {/* Hero image with Tilt effect */}
          <div className={`relative hidden md:block z-10 ${isVisible ? 'animate-scale-in' : 'opacity-0'}`}>
            {/* Wrap the image container with Tilt */}
            <Tilt
              tiltAngleYInitial={5}
              tiltMaxAngleX={10}
              tiltMaxAngleY={10}
              perspective={1000}
              scale={1.02}
              transitionSpeed={2000}
              gyroscope={true}
              className="relative z-10 aspect-square rounded-2xl overflow-hidden shadow-2xl"
            >
              <img
                src="/img/banner-carregados.webp"
                alt="Carros elétricos"
                className="w-full h-full object-cover"
              />
              <div className="absolute z-20 inset-0 bg-gradient-to-br from-black/30 via-black/10 to-transparent"></div>
            </Tilt>
            
            {/* Battery charging indicator animation */}
            <div className="absolute top-3/4 z-30 -right-8 transform -translate-y-1/2 flex items-center justify-center">
              <div className="w-16 h-24 rounded-md bg-white/90 shadow-lg flex flex-col items-center justify-center p-1 border-2 border-primary/70">
                {/* Battery tip */}
                <div className="w-6 h-2 bg-white/90 border-2 border-primary/70 border-b-0 rounded-t-sm -mt-2"></div>
                {/* Battery levels */}
                <div className="w-full h-full flex flex-col-reverse gap-0.5 p-0.5">
                  <div className="w-full h-1/5 bg-blue-500/30 rounded-sm animate-pulse"></div>
                  <div className="w-full h-1/5 bg-blue-500/50 rounded-sm animate-pulse" style={{ animationDelay: '0.2s' }}></div>
                  <div className="w-full h-1/5 bg-blue-500/70 rounded-sm animate-pulse" style={{ animationDelay: '0.4s' }}></div>
                  <div className="w-full h-1/5 bg-blue-500 rounded-sm animate-pulse" style={{ animationDelay: '0.6s' }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Search functionality - Moved outside the grid to span full width */}
        <div className="mt-10 mb-12 relative z-30">
          <div className="max-w-4xl animate-fade-in mx-auto bg-white/95 backdrop-blur-md shadow-xl rounded-xl p-4 border border-gray-100">
            <Selectors
              makers={filteredMaker}
              models={filteredModel}
              states={filteredState}
              selectedMaker={selectedMaker}
              onMakerChange={setSelectedMaker}
              selectedModel={selectedModel}
              onModelChange={setSelectedModel}
              selectedState={selectedState}
              onStateChange={setSelectedState}
            />
          </div>
        </div>
        
        {/* Stats */}
        <div className={`grid grid-cols-2 md:grid-cols-4 gap-8 mt-10 ${isVisible ? 'animate-fade-up' : 'opacity-0'}`} style={{ animationDelay: '0.3s' }}>
          <div className="text-center">
            <h3 className="text-3xl font-bold text-primary">7.000+</h3>
            <p className="text-sm text-foreground/70 mt-1">Usuários</p>
          </div>
          <div className="text-center">
            <h3 className="text-3xl font-bold text-primary">18.000+</h3>
            <p className="text-sm text-foreground/70 mt-1">Rotas Calculadas</p>
          </div>
          <div className="text-center">
            <h3 className="text-3xl font-bold text-primary">1500+</h3>
            <p className="text-sm text-foreground/70 mt-1">Carros Elétricos</p>
          </div>
          <div className="text-center">
            <h3 className="text-3xl font-bold text-primary">1°</h3>
            <p className="text-sm text-foreground/70 mt-1">Marketplace EV</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero; 